@import '../../assets/styles/variables';

.footer-container {
    background-image: linear-gradient(to right, $violet 5%, $purple 99%);
    padding: 2rem 3rem 1rem 3rem;
    overflow: hidden;

    .cookie-settings-overlay {
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        top: 0;
        background-color: #11000080;
        z-index: 2147483001 !important;
    }

    .saved-successfully-overlay {
        position: fixed;
        bottom: 0;
        left: 0;    
        right: 0;
        top: 0;
        background-color: #11000080;
        z-index: 2147483001 !important;

        .saved-successfully-content {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            background-color: white;

            width: 540px;
            height: 272px;
            border-radius: 8px;

            .saved-successfully-title {
                font-size: 20px;
                color: #1E1E1E;
                text-align: center;
            }

            .saved-successfully-icon {
                margin-top: 66px;
                margin-bottom: 24px;
                text-align: center;
                color: #69B578;
            }
        }
    }



    .footer-link {
        color: white;
        margin-left: 2rem;
        white-space: nowrap;

        &:first-of-type {
            margin-left: 0;
        }
    }

    .footer-brand {
        color: white;
        padding: 2rem 0 0 1rem;
        margin-left: 1.5rem;

        &:first-of-type {
            margin-left: 0;
        }
    }

    .text-links-wrap {
        margin-right: auto;
        display: flex;


        ul {
            padding-left: 0;

            li {
                list-style: none;
                margin-bottom: 10px;
                a {
                    font-size: 18px;
                }
            }

            &:nth-of-type(2) {
                margin-left: 40px;
            }

            &:nth-of-type(3) {
                margin-left: 80px;
            }

            &:nth-of-type(4) {
                margin-left: 80px;
            }
        }
    }

    .social-media-apps-icons {
        margin-left: auto;
        display: flex;
        flex-direction: column;

        i {
            color: $white;
        }

        div:nth-of-type(1) {
            margin-bottom: 20px;

            a {
                margin-left: 47px;
                &:first-of-type {
                    margin-left: 0;
                }
            }
        }

        div:nth-of-type(2) {
            margin-left: -15px;
            margin-right: -15px;

            .apple-app-logo {
                height: 47px;
                margin-right: 8px;
            }

            .google-play-logo {
                height: 47px;
                // width: 180px;
                margin-left: 8px;
            }
        }
    }

    .cb-admin-wrap {
        display: flex;
        color: white;

        input {
            margin-left: 1px;
        }
    }

    .cookie-acceptance-wrap {

        width: 100%;
        position: fixed;
        bottom: 2px;
        display: flex;
        left: 0;
				z-index: 2147483001 !important;


        .CookieConsent {
            display: flex;

            background-color: $white;
            padding: 2px;
            padding-top: 11px;
            // width: 1420px;
            border: solid 1px $blue;
            border-radius: 7px;
            margin: 0 auto;
            font-size: 12px;

            >div {
                // margin-top: 9px;
                margin-left: 10px;
                // margin-right: 5px;
            }

            .btn {
                margin-left: auto;
            }

            #rcc-confirm-button {
                background: transparent;
                border: none;
                font-size: 28px;
                color: $blue;
                margin-top: -5px;
            }
           

            @media screen and (max-width: 1270px) {
                margin: 0 20px;
            }
            @media screen and (max-width: 1253px) {
                >div {
                    margin-bottom: 5px;
                }

                #rcc-confirm-button {
                    // margin-top: 5px;
                }
            }
        }

    }

    @media screen and (max-width: 1500px) {
        .text-links-wrap {
            ul {
                &:nth-of-type(2) {
                    margin-left: 20px;
                }

                &:nth-of-type(3) {
                    margin-left: 40px;
                }

                &:nth-of-type(4) {
                    margin-left: 20px;
                }
            }
        }
    }

    @media screen and (max-width: 1375px) {
        margin-left: -40px;

        .fa-3x {
            font-size: 2.5em;
        }

        .text-links-wrap {
            ul {
                &:nth-of-type(2) {
                    margin-left: 0;
                }
            }
        }

        .social-media-apps-icons {
            margin-top: -20px;
            margin-left: 20px;

            div:nth-of-type(1) {
                a {
                    margin-left: 32px;
                    &:first-of-type {
                        margin-left: 5px;
                    }
                }
            }

            div:nth-of-type(2) {
                white-space: nowrap;

                .apple-app-logo {
                    height: 38px;
                    width: 130px;
                }

                .google-play-logo {
                    height: 38px;
                    width: 136px;
                }
            }
        }
    }

    @include media-breakpoint-down(lg) {
        .navbar {
            margin-left: -20px;
            margin-right: -20px;
            padding: 0;
            flex-direction: column;
        }

        .text-links-wrap {
            margin-right: 0;
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
        }

        .social-media-apps-icons {
            margin: 0;
            margin-top: 10px;
            text-align: center;

            .fa-3x {
                font-size: 4em;
            }

            >div:first-of-type {
                a:first-of-type {
                    margin-left: 0;
                }
            }

            div:nth-of-type(2) {
                .apple-app-logo {
                    width: 160px !important;
                    height: 47px !important;
                }
                .google-play-logo {
                    width: 168px !important;
                    height: 47px !important;
                }
            }
        }

        .footer-brand {
            padding: 0;
            margin-top: 10px;
            margin-left: -20px !important;
        }
    }

    @media screen and (max-width: 630px) {
        padding: 0;
        padding-top: 1.3rem;
        padding-bottom: 1rem;

        .navbar {
            margin: 0;
        }

        .text-links-wrap {
            // flex-direction: column;
            flex-wrap: wrap;

            ul {
                margin-bottom: 0;

                &:nth-of-type(1) {
                    flex: 1 0 0;
                    flex-basis: 40%;
                }

                &:nth-of-type(2) {
                    margin-left: 0;
                    flex: 1 0 0;
                    flex-basis: 40%;
                    order: 3;
                    margin-top: -70px;
                }

                &:nth-of-type(3) {
                    margin-left: 0;
                    order: 2;
                    margin-right: 20px;
                    z-index: 1000;
                    // flex: 1 0 0;
                    // flex-basis: 40%;
                }
            }
        }
    }

    @media screen and (max-width: 600px) {
        margin-left: 0 !important;
    }

    @media screen and (max-width: 488px) {
        .text-links-wrap ul {
            // flex-basis: 100%;
            margin-top: 0 !important;
        }

        .footer-brand {
            margin-left: 0 !important;
        }
    }

    @media screen and (max-width: 400px) {

        .fa-3x {
            font-size: 3em !important;
        }

        // .text-links-wrap ul {
        //     &:nth-of-type(2) {
        //         border: solid 1px red;
        //         margin-top: 0px;
        //     }
        //     &:nth-of-type(3) {
        //         margin-left: 0;
        //         order: 2;
        //         margin-right: 0;
        //         margin-left: -10px;
        //     }
        // }

        .social-media-apps-icons {
            div:nth-of-type(2) {
                .apple-app-logo {
                    width: 116px !important;
                    height: 34px !important;
                }
                .google-play-logo {
                    width: 121px !important;
                    height: 34px !important;
                }
            }
        }

    }

    @media screen and (max-width: 342px) {
        .navbar {
            margin-left: -10px;
            margin-right: -10px;
        }
    }
}