@import "node_modules/bootstrap/scss/functions";
@import "node_modules/bootstrap/scss/variables";
@import "node_modules/bootstrap/scss/mixins/_breakpoints";

$gray: #4a4a4a;
$default: $gray;


$purple: #090446;
$orange: #fa8334;
$white: #fff;
$black: #000;
$blue: #00a7e1;
$bluelink: #4a90e2;
$darkblue: #0d81ff;
$violet: #5158bb;
$green: #69b578;
$darkgreen: #388659;
$error: #d0021b;
$gray_btn: #ebeff2;
$border_gray: #ced4da;
$border_dark_gray: #4a4a4a;
$grayheader: #979797;
$lightgray: #9b9b9b;

// Base URL for CDN containing images/icons
$rackspaceURL: 'https://cdn.lexvid.com';