@import '../../../assets/styles/variables';

.cookie-consent-wrap {
	width: 100%;
	position: fixed;
	bottom: 18px;
	z-index: 1000;

	.container {
		padding: 0 !important;

		@include media-breakpoint-down(sm) {
			width: 100% !important;
			margin: 0 !important;
			padding-left: 6px !important;
		}
	}

	.cookie-consent-inner {
		color: #090446;
		background-color: $white;
		font-size: 12px;
		padding: 24px;
		width: 654px;
		border-radius: 10px;
		border: 1px solid #CED4DA;
		box-shadow: 0px 4px 16px 0px #00000040;
		position: relative;

		.close {
			font-size: 2.5rem;
			font-weight: normal;
			position: absolute;
			right: 14px;
			top: 10px;
		}

		> div:first-child {
			color: #090446;
			font-size: 16px;
			font-weight: 600;
			margin-bottom: 1rem;
		}

		.cookie-consent-buttons {
			margin-top: 20px;
			display: flex;
			justify-content: space-between;

			button {
				height: 36px;
				font-size: 14px;
				padding-left: 18px;
				padding-right: 18px;

				&.btn-primary-blue {
					margin-right: 16px;
				}
			}

			>div:nth-child(2) {
				display: flex;
				align-items: center;
			}

			a {
				font-size: 14px;
				margin-left: 28px;
			}
		}

		@include media-breakpoint-down(sm) {
			width: 370px;
			padding: 16px;

			> div:first-child {
				margin-bottom: 8px;
			}

			.cookie-consent-buttons {
				flex-direction: column;
				align-items: flex-start;
				margin-top: 12px;
				
				>div {
					display: flex;
					align-items: center;
					
					&:first-of-type {
						margin-bottom: 12px;
						button {
							// font-size: 12px;
						}
					}
					&:nth-child(2) {
						align-items: flex-start;

						> a{
							margin-left: 16px;
							&:first-of-type {
								margin-left: 0;
							}
						}
					}
				}
			}
		}

		@media screen and (max-width: 630px) {
			margin: auto;
		}

		@media screen and (max-width: 410px) {
			margin-left: 0;
		}
	}

}