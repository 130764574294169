@import '../../../assets/styles/variables';

.error-fallback-container {
    // margin-top: 2em;
    margin-bottom: 2em;
    // position: relative;
    display: flex;
    min-height: 700px;

    h1, h2 {
        margin-top: 80px;
        white-space: nowrap;
    }

    .try-again-wrap {

        width: 640px;
        text-align: center;
        padding-top: 40px;
        padding-bottom: 60px;

        .try-again {
            position: relative;
            padding-left: 60px;
            padding-right: 40px;

            &::before {
                font-family: "Font Awesome 5 Pro";
                position: absolute;
                left: 32px;
                top: 8px;
                font-size: 19px;
                font-weight: 300;
            }
        }
    }

    .info-wrap {
        width: 700px;

        > div {
            margin-bottom: 15px;
        }
    }

    .error-robot {
        // position: absolute;
        // right: 0;
        // top: -60px;
        margin-top: 20px;
        height: 600px;
    }

    @media screen and (max-width: 1440px) {
        h1, h2 {
            white-space: initial;
        }
    }

    @media screen and (max-width: 1250px) {
        padding: 0;

        h1, h2 {
            width: 500px;
        }
        .try-again-wrap {
            width: initial;
        }
        .info-wrap {
            width: initial;
        }
    }

    @media screen and (max-width: 1060px) {
        .error-robot {
            height: 550px;
        }
    }

    @include media-breakpoint-down(md) {
        .error-robot {
            height: 400px;
            margin-left: -20px;
        }
    }

    @media screen and (max-width: 920px) {
        h1, h2{
            width: initial;
        }        
    }

    @include media-breakpoint-down(sm) {
        flex-direction: column;

        .error-robot {
            height: unset;
            margin-left: auto;
            max-width: 400px;
        }
    }

    @media screen and (max-width: 920px) {
        h1, h2 {
            margin-top: 40px;
        }
        padding-left: 20px;
        padding-right: 20px;
    }

    @media screen and (max-width: 440px) {
        .error-robot {
            width: 100%;
        }
    }
}