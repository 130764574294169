@import '../../assets/styles/variables';

.header-nav {
  padding: 1.5rem 1rem;
  // box-shadow: -5px 0 4px 3px rgba(0, 0, 0, 0.5);
  box-shadow: 0 5px 4px -2px rgba(0, 0, 0, 0.5);
  background-image: linear-gradient(to right, $blue 3%, $violet 100%);
  z-index: 10030 !important;
  max-height: 112px;

  .help-phone-wrap {
    display: flex;
  }

  &.navbar-not-auth {
    .cart-icon-wrap {
      margin-top: 2px;
    }
  }

  @media screen and (max-width: 1468px) {
    &.navbar-not-auth .button-signup {
      margin-left: 0;
      margin-right: 0;
    }    
  }

  @media screen and (max-width: 1432px) {
    .button-signup {
      padding: 5px 10px;
    }
    .button-signin {
      padding: 5px 10px;
    }
  }

  // @media screen and (max-width: 1265px) {
  @media screen and (max-width: 1312px) {
    .cart-icon-wrap {
      margin-right: 15px;
    }
    .button-signup {
      margin-right: 0.5em !important;
    }
  }

  // @media screen and (max-width: 1247px) {
  @media screen and (max-width: 1303px) {
    .header-logo {
      margin-left: 0 !important;
    }
  }

  // @media screen and (max-width: 1230px) {
  @media screen and (max-width: 1288px) {
    .menu-link-items {
      align-items: start;
      padding-top: 38px;
    }

    .help-phone-wrap {
      display: block;
    }
  }

  // @media screen and (max-width: 1080px) {
  @media screen and (max-width: 1218px) {
    .button-signup {
      margin: 0 !important;
    }
    .button-signin {
      margin: 0 !important;
      margin-left: 10px !important;
    }
  }

  // @media screen and (max-width: 1050px) {
  @media screen and (max-width: 1118px) {
    .header-logo {
      margin-left: 0 !important;
    }
    .menu-link-items {
      padding-right: 0 !important;
    }
  }

  // @media screen and (min-width: 992px) and (max-width: 1033px) {
  @media screen and (min-width: 992px) and (max-width: 1100px) {
    .header-item {
      margin-right: 12px !important;
      margin-left: 12px !important;
    }
  }

  // @media screen and (max-width: 1017px) {
  @media screen and (max-width: 1076px) {
    .menu-link-items {
      // padding-top: 1rem;
    }
    .help-phone-wrap {
      position: relative;
    }
    .phone-number {
      // position: absolute;
      white-space: nowrap;
      margin: 0 !important;
    }

    form.form-inline {
      margin-left: auto;
    }
  }

  @media screen and (max-width: 1052px) {
    .cart-icon-wrap {
      margin-right: 10px;
      margin-left: auto;
    }
    .phone-number {
      position: absolute;
      left: -45px;
    }
  }

  @media screen and (max-width: 900px) {
    .dt-links-menu {
      .my-courses-and-certificates {
        margin-left: 1rem !important;
      }
    }
  }

  @include media-breakpoint-down(md) {
    .header-logo {
      margin-left: 1rem !important;
    }

    &.navbar-not-auth .cart-icon-wrap {
      display: none;
    }

    .dt-links-menu {
      .find-cle-courses {
        margin-bottom: 0 !important;
        margin-right: 2rem !important;
      }
    }

    .phone-number {
      position: initial;
    }

    .responsive-navbar-nav .header-item {
      margin-left: auto !important;
      margin-right: auto !important;
    }
    .find-cle-courses, .help-phone-wrap {
      margin-bottom: 15px !important;
      margin-left: auto !important;
      margin-right: auto !important;

      >.dropdown-menu {
        display: none !important;
        margin-top: -16px !important;

        .dropdown-item {
          padding: 0 !important;
          text-align: center;
        }
        > a {
          background-color: transparent !important;
          color: white !important;
          margin-left: auto !important;
          margin-right: auto !important;
          > .arrow-right {
            display: none;
          }
        }
      }
    }
  }

  @include media-breakpoint-down(md) {
    &.navbar-not-auth {
      .navbar-collapse {
        // align-items: flex-end;
        position: absolute;
        right: 14px;
        top: 75px;
        z-index: 1050;
        background: #4461c0;
        border-radius: 10px;
        padding: 15px;
  
        .menu-link-items {
          padding: 0;
          margin: 0 !important;
        }
        .header-item {
          margin-bottom: 15px;
        }
  
        .form-inline {
          display: flex;
          flex-direction: column;
  
          .button-signup{
            margin: 0;
            margin-bottom: 15px;
            padding: 5px 10px;
            font-size: 16px;
            height: auto;
          }
          .button-signin {
            margin: 0;
            margin-bottom: 5px;
            padding: 5px 30px;
            font-size: 16px;
            height: auto;
          }
        }
      }

      span {
        display: flex;
        flex-direction: column;
        text-align: center;
      }
    }
  }

  @media screen and (max-width: 870px) {
    .dt-links-menu {
      .my-courses-and-certificates {
        margin-right: 1rem;
      }
      .find-cle-courses {
        margin-right: 1rem !important;
      }
    }
  }

  @media screen and (max-width: 835px) {
    .dt-links-menu {
      .my-courses-and-certificates {
        margin-left: 0 !important;
        margin-right: 0;
      }
      .find-cle-courses {
        margin-right: 0 !important;
      }
    }
  }

  @media screen and (max-width: 790px) {
    .dt-links-menu {
      font-size: 14px;
    }
  }

  @media screen and (max-width: 767px) {
    &.navbar-auth {
      .cart-icon-wrap {
        display: none;
      }

      .navbar-toggler {
        position: relative;
        margin-top: -20px;

        &::after {
          position: absolute;
          white-space: nowrap;
          font-size: 16px;
          top: 45px;
          left: 50%;
          transform: translateX(-50%);
        }
      }

      .mm-item {
        display: block;
        padding: 0.5rem;
				background: #dde0e4;
      }

      .navbar-collapse {
        position: absolute;
        right: 14px;
        top: 62px;
        z-index: 1050;
        background: #dde0e4;
        border-radius: 10px;
        padding: 15px;
        color: #090446;
				min-width: 360px;

        .user-name-email {
          display: flex;
          // justify-content: center;
          align-items: center;
    
          .circle-initials {
            background: $white;
            width: 35px;
            height: 35px;
            border-radius: 50%;
            margin-left: -5px;
            justify-content: center;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-right: 1rem;
          }
        }    

        a {
          color: #090446 !important;
          text-decoration: none;
        }

        .dropdown-toggle {
          padding: 0;
        }
      }

      .mm-dropdown-submenu {
        // background: transparent;
				background: #dde0e4;
        padding-left: 1em;
        
        .arrow-right {
          background-color: transparent;
        }
      }
    }
  }

  @media screen and (min-width: 576px) and (max-width: 600px) {
    // .header-logo {
    //   margin-left: 0 !important;
    // }
    .navbar-brand {
      img {
        width: 130px;
        height: 32px;
      }
    }
  }

  @include media-breakpoint-down(sm) {
    &.navbar-not-auth {

      .button-signup {
        // margin-left: auto;
      }

      // .help-link-mobile {
      //   position: absolute;
      //   right: 38px;
      //   bottom: 5px;
      // }
    }
  }

  @include media-breakpoint-down(xs) {

      .button-signup {
        display: none !important;
      }

      form .button-signup {
        display: block !important;
      }

      // .navbar-toggler {
      //   margin-top: -15px;
      // }
  }

  @media screen and (max-width: 385px) {
    padding: 1rem;

    .navbar-brand {
      margin-left: 0 !important;
      img {
        width: 100px;
        height: 24px;
      }
    }
  }

  .navbar-toggler {
    color: $white;
    border-color: $white;
    border-radius: 10px;
    padding: 5px 10px;
    outline: none;

    span {
      display: inline-block;
      margin-top: 9px;
      margin-right: 5px;
      font-size: 16px;
    }

    .fa-open-menu {
      font-size: 1.5em;
    }
  }

  .dropdown-item {
    &:hover {
      background-color: #dde0e4;

      .arrow-right {
        border-color: transparent transparent transparent #090446;
        background-color: #dde0e4;
      }
    }
  }

  .dropdown-toggle {
    color: $white !important;

    &:after {
      display: none;
    }

    &:hover {
      color: #090446 !important;
    }
  }

  .dropdown-menu {
    border-radius: 0;
    padding: 0;
    border: none;
    background-color: transparent;

    >a,
    div {
      background-color: $white;
    }
  }

  .my-courses-and-certificates {
    margin-left: 2rem;
    margin-right: 2rem;

    >.dropdown-menu {
      padding-top: 31px !important;
      min-width: 17em;

      .dropdown-item {
        padding: 0.75rem 1rem;
      }
    }
  }

  .find-cle-courses {
    display: inline;
    >a{
      display: inline;
    }

    >button {
      background: transparent;
      border: none;
      color: #fff;
      font-weight: normal;

      &:hover,
      &:focus,
      &:active,
      &:visited {
        color: #090446;
        background: transparent !important;
        background-color: transparent !important;
        outline: none;
        box-shadow: none !important;
      }
    }

    >.dropdown-menu {
      padding-top: 31px !important;
      min-width: 17em;

      .dropdown-item {
        padding: 0.75rem 1rem;

        &.active {
          color: #090446;
        }
      }
    }
  }

  .dropdown-submenu {
    position: relative;

    >.dropdown-menu {
      top: 0;
      left: 100%;
      margin-top: -1px;
      margin-left: -1px;
      padding: 0;
      border-radius: 0;
      border: none;
      background-color: #eef1f4;

      div,
      a {
        background-color: #eef1f4;

        &:hover {
          background-color: #dde0e4;
        }
      }
    }

    &:hover {
      // background: transparent;
      background-color: #dde0e4;


      >.dropdown-menu {
        display: block;
      }

      >a:after {
        border-left-color: #fff;
      }

      >.dropdown-item {

        .arrow-right {
          border-color: transparent transparent transparent #090446;
          background-color: #dde0e4;
        }
      }

    }

    .dropdown-submenu .dropdown-submenu>a:after {
      display: block;
      content: " ";
      float: right;
      width: 0;
      height: 0;
      border-color: transparent;
      border-style: solid;
      border-width: 5px 0 5px 5px;
      border-left-color: #ccc;
      margin-top: 5px;
      margin-right: -10px;
    }

    &.pull-left {
      float: none;

      >.dropdown-menu {
        left: -100%;
        margin-left: 10px;
        border-radius: 0;
      }
    }
  }

  .packages-and-pricing {
    text-decoration: none;
    margin-left: 3rem !important;

    &:hover,
    &:focus {
      color: #090446 !important;
    }
  }

  .user-menu-button {
    margin-right: 2rem;
    margin-top: -1rem;

    button:first-child {
      background: #d8d8d8;
      width: 51px;
      height: 51px;
      border-radius: 50%;

      display: inline-flex;
      justify-content: center;
      align-items: center;
      appearance: none;
      box-sizing: border-box;
      margin: 0;
      padding: 0;
      font-family: inherit;
      font-weight: 500;
      letter-spacing: 0.07em;
      line-height: 1;
      cursor: pointer;
    }

    .dropdown-toggle::before {
      position: absolute;
      top: 19px;
      color: #5158bb;
      font-weight: bold;
    }

    .dropdown-toggle::after {
      position: absolute;
      display: block;
      top: 52px;
      border: 0;

			// TODO: make whole widget shift left for long names
			max-width: 148px;
			// min-width: 150px;
			width: 148px;

			overflow: hidden;
			left: -49px;
    }

    .dropdown-menu-right {
      margin-right: -48px !important;
      border-radius: 0;
      padding-top: 32px !important;
      background-color: transparent;

      // background-color: #dde0e4;
      background-clip: border-box;
      border: none;

      .dropdown-item {
        color: #090446;
        padding: 0.5rem 1.5rem;
      }

      a,
      div {
        background-color: #dde0e4;

        &:hover {
          background-color: $white;

          .circle-initials {
            background-color: #dde0e4;
          }

          div {
            background-color: $white;
          }

          .arrow-right {
            background-color: $white;
          }
        }
      }

    }

    .user-name-email {
      display: flex;
      // justify-content: center;
      align-items: center;

      .circle-initials {
        background: $white;
        width: 35px;
        height: 35px;
        border-radius: 50%;
        margin-left: -5px;
        justify-content: center;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 1rem;
      }
    }

  }

  .arrow-right {
    top: 2px;
    position: relative;
    display: inline-block;
    margin-right: 0.5rem;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 8px 0 8px 16px;
    border-color: transparent transparent transparent $blue;

    &:hover,
    &:focus {
      border-color: transparent transparent transparent #090446;
      background-color: #dde0e4;
    }
    &.arrow-down {
      transform: rotate(90deg);
    }
  }

  .navbar-light .navbar-nav .nav-link {
    color: $white;
  }

  .header-item {
    margin-right: 1rem;
    margin-left: 1rem;
    color: $white;
  }

  .header-logo {
    margin-left: 1rem;
  }

  .button-signup {
    line-height: inherit;
    margin-right: 1.5em;
    margin-left: 10px;
    margin-top: 10px;
    margin-bottom: 10px;

    @media screen and (max-width: 1222px) {
      margin-right: 0;
    }
  }

  .button-signin {
    line-height: inherit;
    margin: 10px;
  }

  .find-cle-courses-dropdown {
    display: inline;
    > a{
      display: inline;
    }
  }
}

@media screen and (max-width: 599px) {
  body {
    margin-top: 95px;
    
    &.packages-page {
      margin-top: 113px;
    }
  }

  .header-nav {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1030;
    }

  .compliance-subheader-wrap {
    display: none;         
  }
}

@media screen and (max-width: 385px) {
  body {
    margin-top: 74px;

    &.packages-page {
      margin-top: 90px;
    }
  }
}

@media screen and (max-width: 988px) {
  .my-courses-and-certificates {
      margin-left: 3rem !important;
  }

  .find-cle-courses {
    margin-right: 10vw;
  }

  .packages-and-pricing {
    padding: 0 0.5rem;
  }
}

@media screen and (max-width: 900px) {
  .find-cle-courses {
    margin-right: 5vw;
  }
}

@media screen and (max-width: 830px) {
  .my-courses-and-certificates {
    margin-left: 1rem !important;
  }

  .find-cle-courses {
    margin-right: 2rem;
  }

  .header-nav .packages-and-pricing {
    margin-left: 1rem !important;
  }
}

@media screen and (max-width: 790px) {
  .header-nav .my-courses-and-certificates {
    margin-right: 0;
  }
}

.live-course-banner-wrap {
  background-color: #D9D9D9;
  color: #000;
  padding: 14px 19px;
  font-size: 16px;

  .title-line {
    display: flex;
    align-items: center;

    .title {
      font-weight: 700;
      font-size: 24px;
    }

    a, button {
      margin-left: 20px;
    }

    .hide-show {
      color: #00A7E1;
      margin-left: auto;
      text-decoration: none;
    }

    @media screen and (max-width: 600px) {
      .hide-show {
        display: none;
      }
    }
  }

  .content {
    display: flex;
    align-items: center;
    margin-top: 21px;

    a, button {
      margin-right: 21px;
      white-space: nowrap;
    }

    button {
      padding: 9px 13px;
    }

    .line-1 {
      font-weight: 700;
    }

    .line-2 {
      margin-top: 5px;

      span:nth-of-type(2) {
        padding-left: 11px;
      }
      span:nth-of-type(3) {
        padding-left: 20px;
      }
    }

    @media screen and (max-width: 842px) {
      align-items: flex-start;

      .line-2 {
        margin-top: 0;

        span {
          display: block;
          margin-top: 5px;
        }
  
        span:nth-of-type(2) {
          padding-left: 0;
        }
        span:nth-of-type(3) {
          padding-left: 0;
        }
      }
    }

    @media screen and (max-width: 600px) {
      .line-2 {
        display: none;
      }
    }

    @media screen and (max-width: 471px) {
      flex-direction: column-reverse;
      margin-top: 8px;

      a, button {
        margin-top: 11px;
      }      
    }

  }
}