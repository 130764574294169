.cart-icon-wrap {
  margin-right: 32px;
  margin-top: -9px;

  .shopping-cart-icon {
    cursor: pointer;
    display: inline-flex;
    background: #00A7E1;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    position: relative;

    i.fa-shopping-cart {
      color: white;
      position: absolute;
      font-size: 20px;
      top: 11px;
      right: 10px;
    }

    i.fa-circle.transparent-circle {
      position: absolute;
      color: #5158bb;
      font-size: 20px;
      right: -4px;
      top: -4px;

      &:hover {
        color: transparent;
      }
    }

    i.fa-circle.orange-circle {
      position: absolute;
      color: #EC9C25;
      font-size: 16px;
      right: -2px;
      top: -2px;

      &:hover {
        color: #EC9C25;
      }
    }

    .number-of-products {
      color: white;
      font-size: 12px;
      font-weight: bold;
      position: absolute;
      right: 2px;
      top: -4px;
    }
  }

}