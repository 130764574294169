@mixin circle($circle-size) {
	position: relative;
	width: $circle-size;
	height: $circle-size;
	border-radius: 50%;
	padding: 0;
}

@mixin btn-blue() {
	background-color: $blue;

	&:hover,
	&:active,
	&:focus,
	&:not(:disabled):not(.disabled):active,
	&:not(:disabled):not(.disabled).active,
	.show>&.dropdown-toggle {
		color: $blue;
		background-color: $white;
		border-color: $blue;
	}
}

@mixin btn-secondary {
  background-color: $white;
  border-color: $blue;
  color: $blue;

  &:hover {
    background-color: $blue;
    border-color: $blue;
    color: $white;
  }

  &:hover,
  &:active,
  &:focus,
  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled).active,
  .show>&.dropdown-toggle {
    background-color: $blue;
    border-color: $blue;
    color: $white;
  }

  &:disabled {
	background-color: white;
    border-color: #D9D9D9;
	color: #D9D9D9;
  }
}


@mixin btn-icon($iconUrl, $hoverIconUrl, $width, $height) {
	border-radius: 0;
	padding: 0;
	width: $width;
	height: $height;
	background-size: $width $height;
	background-image: url($iconUrl);
	background-repeat: no-repeat;

	&:focus {
		box-shadow: none;
	}

	&:hover {
		background-image: url($hoverIconUrl);
	}
}