@import '../../../assets/styles/variables';

.pushdown {
    width: 100%;
    text-align: center;
    color: #5058BB;
    font-size: calc(22em / 16);
    padding: 0.5rem;

    >img {
        width: 38px;
    }
}