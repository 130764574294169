@import '../../../assets/styles/variables';

.compliance-subheader-wrap {
    width: 100%;
    background-image: linear-gradient(to right, $blue 3%, $violet 100%);

    .carousel {
        box-shadow: none;
        border: none;
        width: 100%;
        margin-left: 0;
    }

    .state-compliance-wrap {
        color: $white;
        padding: 1em 3em 1em 2em;
        width: 100%;
        display: flex;

        .carousel-item {
            display: flex;

            .summary-wrap {
                flex-grow: 1;
            }
            .my-playlist {
                flex-grow: 0;
                white-space: nowrap;
            }
        }
    }

    a {
        color: $white;
        &:hover {
            text-decoration: none;
            color: #090446 !important;
        }
    }

    @media screen and (max-width: 785px) {
        display: none;
    }
}