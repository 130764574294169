.cart-overlay-backdrop {
  position: fixed;
  width: 100vw;
  height: 100vh;
  color: #090446;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 100000;
  top: 104px;
  right: 0;

  .cart-overlay-wrap {
    background-color: white;
    position: absolute;
    right: 0;
    top: 0;
    width: 515px;

    .cart-overlay-title {
      display: flex;
      font-size: 17px;
      justify-content: space-between;
      font-size: 24px;
      font-weight: bold;
      padding: 42px 32px 42px 32px;
      
      .fa-times {
        color: #ADADAD;
        font-size: 24px;
      }
    }

    .empty-cart-wrap {
      text-align: center;
      
      > img {
        margin-top: 73px;
        margin-bottom: 100px;
      }

      .total-and-buttons {
        padding: 42px 32px 42px 32px;

        .btn {
          width: 100%;
          height: 51px;
          font-size: 16px;
        }  
      }

    }

    .product-line {
      display: flex;
      justify-content: space-between;
      padding: 0 32px 42px 32px;

      .price-strike {
        display: block;
        text-decoration: line-through;
        font-size: 14px;
      }

      .price-sale {
        color: #69B578;
        font-weight: 500;
      }
    }

    .total-and-buttons {
      box-shadow: 0px 3px 40px 0px #00000012;
      padding: 25px 32px 42px 32px;

      .discount {
        text-align: center;
        color: #000;
        font-size: 14px;

        > span {
          color: #FF5454;
          margin-left: 32px;          
        }
      }

      .total {
        text-align: center;
        color: #000;
        font-size: 14px;
        font-weight: bold;
        margin-bottom: 38px;

        > span {
          display: inline-block;
          margin-left: 32px;
          font-size: 22px;
        }
      }

      .buttons {
        display: flex;
        justify-content: space-between;

        > .btn {
          width: 215px;
          height: 51px;
          font-size: 16px;
        }
      }
    }
  }

  @media screen and (max-width: 530px) {
    .cart-overlay-wrap {
      width: 100%;
    }    
  }

  @media screen and (max-width: 500px) {
    .total-and-buttons .buttons {
      flex-direction: column;
      
      > .btn {
        width: 100% !important;

        &:first-child {
          margin-bottom: 15px;
        }
      }
    }
  }

}