.finish-setting-up-account-wrap {
  text-align: center;
  background: linear-gradient(90deg, #FAAB34 0%, #E5951E 100%);
  height: 42px;
  color: white;
  font-size: 14px;
  padding: 9px;
  font-weight: 600;
  letter-spacing: 0.01em;

  .fa-circle {
    font-size: 20px;
  }

  .fa-exclamation {
    color:#E5951E;
    font-size: 11px;
    top: -2px;
  }

  .fa-arrow-right {
    margin-left: 10px;
    font-size: 16px;
  }

  @media screen and (max-width: 512px) {
    height: 62px;
  }

  @media screen and (max-width: 284px) {
    height: 83px;
  }

}